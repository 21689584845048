@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap");

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    width: 6px;
    background: RGBA(0, 0, 0, 0.16); 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: RGBA(0, 0, 0, 0.48); 
    border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: RGBA(0, 0, 0, 0.64); 
}